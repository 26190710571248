import React from 'react';
import clsx from 'clsx';

const Palette = (): JSX.Element => {
  const swatchClassList = 'w-24 h-24 flex items-end justify-center';

  const custom = {
    title: 'Custom',
    colors: [
      { className: 'rt-anchor-blue', hex: '#45729d', text: 'text-white' },
      { className: 'rt-green', hex: '#05d477' },
      { className: 'rt-green-light', hex: '#06e07e' },
      { className: 'rt-green-dark', hex: '#03703f', text: 'text-white' },
      { className: 'rt-grey-smoke', hex: '#abb7c2' },
      { className: 'rt-pale-sky', hex: '#657687', text: 'text-white' },
      { className: 'rt-snow-white', hex: '#ffffff' },
      { className: 'rt-steely-grey', hex: '#657687', text: 'text-white' },
      { className: 'rt-white-smoke', hex: '#f4f4f4' },
      { className: 'rt-white-ice', hex: '#dafeee' },
      {
        className: 'rt-smoke-lighter',
        hex: 'rgba(0, 0, 0, 0.75)',
        text: 'text-white text-xs',
      },
      { className: 'rt-rating-excellent', hex: '#386336', text: 'text-white' },
      { className: 'rt-rating-strong', hex: '#6EBF5F', text: 'text-white' },
      { className: 'rt-rating-average', hex: '#F0A935', text: 'text-white' },
      { className: 'rt-rating-weak', hex: '#F0592E', text: 'text-white' },
      { className: 'rt-rating-poor', hex: '#9E1B21', text: 'text-white' },
    ],
  };

  const grays = {
    title: 'Grays',
    colors: [
      { className: 'gray-100', hex: '#f3f4f6' },
      { className: 'gray-200', hex: '#e2e7eb' },
      { className: 'gray-300', hex: '#d1d5db' },
      { className: 'gray-400', hex: '#9ca3af' },
      { className: 'gray-500', hex: '#6b7280', text: 'text-white' },
      { className: 'gray-600', hex: '#4b5563', text: 'text-white' },
      { className: 'gray-700', hex: '#374151', text: 'text-white' },
      { className: 'gray-800', hex: '#1f2937', text: 'text-white' },
      { className: 'gray-900', hex: '#111827', text: 'text-white' },
    ],
  };

  const reds = {
    title: 'Reds',
    colors: [
      { className: 'red-100', hex: '#fee2e2' },
      { className: 'red-200', hex: '#fecaca' },
      { className: 'red-300', hex: '#fca5a5' },
      { className: 'red-400', hex: '#f87171' },
      { className: 'red-500', hex: '#ef4444', text: 'text-white' },
      { className: 'red-600', hex: '#dc2626', text: 'text-white' },
      { className: 'red-700', hex: '#b91c1c', text: 'text-white' },
      { className: 'red-800', hex: '#991b1b', text: 'text-white' },
      { className: 'red-900', hex: '#7f1d1d', text: 'text-white' },
    ],
  };

  const yellows = {
    title: 'Yellows',
    colors: [
      { className: 'yellow-100', hex: '#fef3c7' },
      { className: 'yellow-200', hex: '#fde68a' },
      { className: 'yellow-300', hex: '#fcd34d' },
      { className: 'yellow-400', hex: '#fbbf24' },
      { className: 'yellow-500', hex: '#f59e0b', text: 'text-white' },
      { className: 'yellow-600', hex: '#d97706', text: 'text-white' },
      { className: 'yellow-700', hex: '#b45309', text: 'text-white' },
      { className: 'yellow-800', hex: '#92410e', text: 'text-white' },
      { className: 'yellow-900', hex: '#78350f', text: 'text-white' },
    ],
  };

  const greens = {
    title: 'Greens',
    colors: [
      { className: 'green-100', hex: '#' },
      { className: 'green-200', hex: '#' },
      { className: 'green-300', hex: '#' },
      { className: 'green-400', hex: '#' },
      { className: 'green-500', hex: '#', text: 'text-white' },
      { className: 'green-600', hex: '#', text: 'text-white' },
      { className: 'green-700', hex: '#', text: 'text-white' },
      { className: 'green-800', hex: '#', text: 'text-white' },
      { className: 'green-900', hex: '#', text: 'text-white' },
    ],
  };

  const blues = {
    title: 'Blues',
    colors: [
      { className: 'blue-100', hex: '#dbeafe' },
      { className: 'blue-200', hex: '#bfdbfe' },
      { className: 'blue-300', hex: '#93c4fd' },
      { className: 'blue-400', hex: '#60a5fa' },
      { className: 'blue-500', hex: '#3b82f5', text: 'text-white' },
      { className: 'blue-600', hex: '#2563eb', text: 'text-white' },
      { className: 'blue-700', hex: '#1d4fd8', text: 'text-white' },
      { className: 'blue-800', hex: '#1e40af', text: 'text-white' },
      { className: 'blue-900', hex: '#1e3a8a', text: 'text-white' },
    ],
  };

  const indigos = {
    title: 'Indigos',
    colors: [
      { className: 'indigo-100', hex: '#' },
      { className: 'indigo-200', hex: '#' },
      { className: 'indigo-300', hex: '#' },
      { className: 'indigo-400', hex: '#' },
      { className: 'indigo-500', hex: '#', text: 'text-white' },
      { className: 'indigo-600', hex: '#', text: 'text-white' },
      { className: 'indigo-700', hex: '#', text: 'text-white' },
      { className: 'indigo-800', hex: '#', text: 'text-white' },
      { className: 'indigo-900', hex: '#', text: 'text-white' },
    ],
  };

  const purples = {
    title: 'Purples',
    colors: [
      { className: 'purple-100', hex: '#' },
      { className: 'purple-200', hex: '#' },
      { className: 'purple-300', hex: '#' },
      { className: 'purple-400', hex: '#' },
      { className: 'purple-500', hex: '#', text: 'text-white' },
      { className: 'purple-600', hex: '#', text: 'text-white' },
      { className: 'purple-700', hex: '#', text: 'text-white' },
      { className: 'purple-800', hex: '#', text: 'text-white' },
      { className: 'purple-900', hex: '#', text: 'text-white' },
    ],
  };

  const pinks = {
    title: 'Pinks',
    colors: [
      { className: 'pink-100', hex: '#' },
      { className: 'pink-200', hex: '#' },
      { className: 'pink-300', hex: '#' },
      { className: 'pink-400', hex: '#' },
      { className: 'pink-500', hex: '#', text: 'text-white' },
      { className: 'pink-600', hex: '#', text: 'text-white' },
      { className: 'pink-700', hex: '#', text: 'text-white' },
      { className: 'pink-800', hex: '#', text: 'text-white' },
      { className: 'pink-900', hex: '#', text: 'text-white' },
    ],
  };

  const colorList = [
    custom,
    grays,
    reds,
    yellows,
    greens,
    blues,
    indigos,
    purples,
    pinks,
  ];

  const getColourBlock = ({ title, colors }) => (
    <>
      <h2 className="text-xl">{title}</h2>
      <div className="grid grid-cols-3 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-9">
        {colors.map(({ className, hex, text = '' }) => (
          <div className="flex flex-col items-center mb-4">
            <div className={clsx(`bg-${className}`, swatchClassList, text)}>
              {hex || 'TBD'}
            </div>
            <div className="text-sm">{className}</div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div>
      <h1 className="text-2xl mb-12">Tailwind Colour Palette</h1>
      {colorList.map((entry) => getColourBlock(entry))}
    </div>
  );
};

export default Palette;
