import * as React from 'react';
import '../styles/global.css';
import Layout from '../components/Layout';
import Palette from '../components/Design/Palette';
import SchemaOrg from '../components/SEO/SchemaOrg';

const IndexPage = (): JSX.Element => (
  <Layout title="grt100">
    <div className="w-100 p-4 max-w-4xl mx-auto">
      <Palette />
    </div>
    <SchemaOrg
      description=""
      name="RepTrak"
      url="https://www.reptrak.com"
      logo="../../img/logos/reptrak-aurora.png"
      type="Organization"
    />
  </Layout>
);

export default IndexPage;
